import React, { useEffect } from 'react'
import { css } from 'glamor';
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import ShopifyStore from './ShopifyStore'
import './PageHeader.css'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import reordify_rect from '../../static/images/reordify.svg'

const PageHeaderWithImage = ({
  title,
  logoImage,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  toast.configure()
  let mystyle = css({
        color: '#F97F00',
        fontSize: '20px',
	direction: 'rtl',
        'text-decoration': 'none'
      });
  const img_map = { reordify_rect: reordify_rect }
  useEffect(() => {
    toast(<ShopifyStore />, {
      bodyClassName: mystyle,
      closeButton: false,
      draggable: false,
      toastId: 1,
      closeOnClick: false,
      autoClose: false
    })
  })
  return (
    <div className={`PageHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="container relative">
        {logoImage && (
          <Image resolutions="1x" src={img_map[logoImage]} alt={title} />
        )}
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  )
}

PageHeaderWithImage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeaderWithImage
