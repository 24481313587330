import React from 'react'
import Image from './Image'

import shopify_logo from '../../static/images/shopify.svg'

const ShopifyStore = () => {
  return (
      <>
      Get it <strong>now</strong> on the
      <a href="https://apps.shopify.com/reordify" target="_blank" rel="noopener noreferrer">
      <Image resolutions="1x" src={shopify_logo} alt={'shopify app store'} />
      </a></>
  )
}

export default ShopifyStore
